export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"","hid":"description"},{"name":"format-detection","content":"telephone=no"},{"name":"google-site-verification","content":"waRDj3P2Bma6XmeQ201DFFo02Zu6_D4EkOO-dBL4RCI"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon/CQTS_152x152.png","sizes":"152x152"},{"rel":"icon","type":"image/png","href":"/favicon/CQTS_76x76.png","sizes":"76x76"},{"rel":"icon","type":"image/png","href":"/favicon/CQTS_60x60.png","sizes":"60x60"},{"rel":"icon","type":"image/png","href":"/favicon/CQTS_48x48.png","sizes":"48x48"},{"rel":"icon","type":"image/png","href":"/favicon/CQTS_32x32.png","sizes":"32x32"},{"rel":"icon","type":"image/png","href":"/favicon/CQTS_16x16.png","sizes":"16x16"}],"style":[],"script":[{"src":"https://unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js","defer":true}],"noscript":[],"title":"Québec sans tabac : portail de la lutte contre le tabagisme","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false