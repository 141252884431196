export default {
  fr: {
    low: {
      content:
        "<p>Votre résultat indique que votre niveau de confiance aurait avantage à augmenter avant de passer à l'étape suivante : l'arrêt. Cela augmenterait vos chances de succès.</p><p>Nous vous suggérons les options suivantes&nbsp;:</p><ul><li>Revoir les <a href='/jarrete/je-me-prepare-a-arreter/pensees-obstacles'>pensées obstacles</a></li><li>Rejoindre le <a href='https://www.facebook.com/groups/603833893144097/?multi_permalinks=667057923488360&notif_t=like&notif_id=1495549026302666' target='_blank'>groupe d’entraide</a> J’ARRÊTE sur Facebook</li><li>Appeler les spécialistes de la ligne J’ARRÊTE au 1 866 527-7383</li><li>Rencontrer un spécialiste dans le <a href='/jarrete/aide-personne'>centre d’abandon du tabagisme (CAT)</a> le plus près de chez vous</li><li>Nous écrire par <a href='/jarrete/aide-telephone'>courriel</a></li></ul>",
    },
    medium: {
      content:
      "<p>Votre résultat indique que vous pourriez dès maintenant passer à la prochaine étape : l'arrêt. Pour cela, utilisez l'un des trois accompagnements personnalisés et gratuits de J'ARRÊTE. Avec de l’aide, vous augmenteriez sûrement votre confiance.</p><p>Sinon, nous vous suggérons les options suivantes&nbsp;:</p><ul><li>Revoir les <a href='/jarrete/je-me-prepare-a-arreter/pensees-obstacles'>pensées obstacles</a></li><li>Rejoindre le <a href='https://www.facebook.com/groups/603833893144097/?multi_permalinks=667057923488360&notif_t=like&notif_id=1495549026302666' target='_blank'>groupe d’entraide</a> J’ARRÊTE sur Facebook</li><li>Appeler les spécialistes de la ligne J’ARRÊTE au 1 866 527-7383</li><li>Rencontrer un spécialiste dans le <a href='/jarrete/aide-personne'>centre d’abandon du tabagisme (CAT)</a> le plus près de chez vous</li><li>Nous écrire par <a href='/jarrete/aide-telephone'>courriel</a></li></ul><br><br><p>Si vous vous sentez prêt, <a href='https://www.quebecsanstabac.ca/jarrete/inscription' target='_blank'>créer votre compte sur la plateforme en ligne J'ARRÊTE</a>.<br>Nous vous aiderons à augmenter votre confiance avec notre méthode efficace pour cesser de fumer/vapoter.</p>",
    },
    high: {
      content:
        "<p><strong>EXCELLENT!<br></strong></p><p>Ce niveau de confiance indique que vos chances de réussir à cesser de fumer/vapoter sont très bonnes. Nous vous invitons donc à passer dès maintenant à la prochaine étape : l'arrêt. Pour cela, utilisez l'un des trois accompagnements personnalisés et gratuits de J'ARRÊTE.<br><br>Si vous vous sentez prêt, <a href='https://www.quebecsanstabac.ca/jarrete/inscription' target='_blank'>créer votre compte sur la plateforme en ligne J'ARRÊTE</a>.<br>Nous vous aiderons à augmenter votre confiance avec notre méthode efficace pour cesser de fumer/vapoter.</p>",
    },
  },
  en: {
    low: {
      content:
        '<p>Your result indicates that it would be best to increase your confidence level before moving on to the next section. You’ll increase your chances of success.</p><p>We suggest you the following options: </p><ul><li>Review the <a href="/iquitnow/getting-ready-to-quit/mental-roadblocks">Mental Roadblocks</a></li><li>Join the I QUIT NOW <a href="https://www.facebook.com/groups/603833893144097/?multi_permalinks=667057923488360&notif_t=like&notif_id=1495549026302666" target="_blank">support group</a> on Facebook</li><li>Call the I QUIT NOW helpline (1-866-527-7383) and speak with a specialist who will help you quit smoking/vaping</li><li>Find the <a href="/iquitnow/person-help">Quit Smoking Centre</a> nearest you and meet with a specialist who will help you quit smoking/vaping</li><li><a href="/iquitnow/phone-help">Email</a> us</li></ul>',
    },
    medium: {
      content:
        '<p>Your answers indicate a certain lack of confidence in your ability to not smoke/vape in certain situations. With help, you will surely increase your confidence.</p><p>We suggest the following options: </p><ul><li>Review the <a href="/iquitnow/getting-ready-to-quit/mental-roadblocks">Mental Roadblocks</a></li><li>Join the I QUIT NOW <a href="https://www.facebook.com/groups/603833893144097/?multi_permalinks=667057923488360&notif_t=like&notif_id=1495549026302666" target="_blank">support group</a> on Facebook</li><li>Call the I QUIT NOW helpline (1-866-527-7383) and speak with a specialist who will help you quit smoking/vaping</li><li>Find the <a href="/iquitnow/person-help">Quit Smoking Centre</a> nearest you and meet with a specialist who will help you quit smoking/vaping</li><li><a href="/iquitnow/phone-help">Email</a> us</li></ul><p>You could also proceed to the next section THAT\'S IT, I QUIT! and <a href="https://www.quebecsanstabac.ca/iquitnow/registration" target="_blank">create your account</a>.  <br>We will help you increase your confidence with our new effective method to stop smoking/vaping.</p>',
    },
    high: {
      content:
        '<p><strong>EXCELLENT!</strong></p><p>This level of confidence indicates that your chances of success in quitting smoking are very good. We recommend you go to the next section THAT\'S IT, I QUIT! and <a href="https://www.quebecsanstabac.ca/iquitnow/registration" target="_blank">create your account</a>.</p><p>You\'ll learn a method that is recognised as effective in getting rid of cigarettes/vapes.</p>',
    },
  },
};
